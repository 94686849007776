import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV

export default class ConfiguracionesService {
  listarConfiguraciones (params) {
    return http.get(`${baseUrl}/configuracion/listar-cofiguraciones-tabla-ov`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  listarColumnasTabla () {
    return http.get(`${baseUrl}/configuracion/listar-columnas`, {
      headers: {
        loading: true
      }
    })
  }

  listarColumnasTablaCrear (params) {
    return http.get(`${baseUrl}/configuracion/listar-columnas-crear`, {
      params,
      headers: {
        loading: true
      }
    })
  }

  eliminarCofiguracion (params) {
    return http.put(`${baseUrl}/configuracion/eliminar-configuracion`, params, {
      headers: {
        loading: true
      }
    })
  }

  crearCofiguracion (params) {
    return http.post(`${baseUrl}/configuracion/crear`, params, {
      headers: {
        loading: true
      }
    })
  }

  crearColumnas (params) {
    return http.post(`${baseUrl}/configuracion/crear-columnas`, params, {
      headers: {
        loading: true
      }
    })
  }

  listarTiposConfiguracionBodegas () {
    return http.get(`${baseUrl}/tipo-bodegas/search`, {
      headers: {
        loading: true
      }
    })
  }

  validarNumerosAutorizacion (params) {
    return http.get(`${baseUrl}/autorizacion/validar-numeros-autorizacion`, {
      params,
      headers: {
        loading: true
      }
    })
  }
}
